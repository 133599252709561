// src/store.js
import { create } from "zustand";

const useStore = create((set) => ({
  company: null,
  setCompany: (company) => set({ company }),
  categories: [],
  setCategories: (categories) => set({ categories }),
  products: [],
  setProducts: (products) => set({ products }),
  searchQuery: "",
  setSearchQuery: (query) => set({ searchQuery: query }),
  selectedCategory: null,
  setSelectedCategory: (category) => set({ selectedCategory: category }),
  orders: [],
  setOrders: (orders) => set({ orders }),
  showInvoice: false,
  setShowInvoice: (showInvoice) => set({ showInvoice }),
  translations: {},
  setTranslations: (translations) => set({ translations }),
  langCodes: [],
  setLangCodes: (langCodes) => set({ langCodes }),
  currentLangCode: "ru",
  setCurrentLangCode: (langCode) => set({ currentLangCode: langCode }),
}));

export default useStore;
