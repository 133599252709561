import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import initializeI18n from "./services/i18";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Initialize i18n before rendering the App
initializeI18n()
  .then(() => {
    root.render(
      // <React.StrictMode>
      <App />
      // </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error("Failed to initialize i18n:", error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
