import { useEffect, useState } from "react";
import qs from "qs";
import i18next from "i18next";

// axios
import axios from "axios";
// react-router-dom
import { useLocation } from "react-router-dom";
// store
import useStore from "../../../store";
import useTranslation from "../../../hooks/useTranslation";

const LanguageDropdown = () => {
  const {
    setTranslations,
    setLangCodes,
    setCurrentLangCode,
    langCodes,
    currentLangCode,
  } = useStore((state) => state);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { tr } = useTranslation();

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const changeLang = (lang) => {
    if (!lang || !lang.code) {
      console.error("Invalid language object:", lang);
      return;
    }
    if (!i18next.isInitialized) {
      console.error("i18next is not initialized");
      return;
    }

    setIsDropdownOpen((prev) => !prev);

    i18next.changeLanguage(lang.code);
    setCurrentLangCode(lang.code);
    localStorage.setItem("language", lang.code);
  };

  const loadClientLanguages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}app/languages/client/get`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const storedLanguage = localStorage.getItem("language") || "ru";
      const langTypes = response.data.data;

      const currentLang =
        langTypes.find((lang) => lang.code === storedLanguage) ||
        langTypes.find((lang) => lang.is_main);

      setLangCodes(langTypes);
      setCurrentLangCode(currentLang.code);

      localStorage.setItem("language", currentLang.code);
    } catch (error) {
      console.error("Error loading client languages:", error);
    }
  };

  function fetchTranslations() {
    const queryString = qs.stringify(
      {
        filter: {
          type: 4,
        },
        per_page: 1000,
      },
      {
        encode: false,
        arrayFormat: "brackets",
      }
    );

    axios
      .get(
        `${process.env.REACT_APP_API_URL}app/translations/client/get?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setTranslations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching translations:", error);
      });
  }

  useEffect(() => {
    fetchTranslations();
    loadClientLanguages();
  }, []);

  return (
    <div className="cm-dropdown">
      <div className="cm-dropdown-label" onClick={toggleDropdown}>
        {tr(
          langCodes
            .find((lang) => lang.code === currentLangCode)
            ?.code?.toUpperCase()
        )}
      </div>
      <div className="cm-dropdown-list-wrapper">
        {isDropdownOpen && (
          <div className="cm-dropdown-list">
            {langCodes && langCodes.length > 0 ? (
              langCodes.map((lang) => (
                <span
                  className="cm-dropdown-item"
                  key={lang.id}
                  onClick={() => changeLang(lang)}
                >
                  {tr(lang.title)}
                </span>
              ))
            ) : (
              <span>{tr("No languages available")}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageDropdown;
