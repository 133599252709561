import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const initializeI18n = () => {
  const options = {
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: false,
    // backend: {
    //   loadPath: `${process.env.REACT_APP_API_URL}`,
    // },
  };

  return i18n.use(XHR).use(initReactI18next).init(options); // This returns a Promise
};

export default initializeI18n;
