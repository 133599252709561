import { useEffect, useState } from "react";
// store
import useStore from "../../store";
// css
import "./categories.css";

export default function Categories() {
  const { categories, selectedCategory, setSelectedCategory } = useStore(
    (state) => state
  );

  const [isCategroy, setIsCategory] = useState(false);

  useEffect(() => {
    if (categories.length > 0) {
      setSelectedCategory(categories[0].id);
    }
  }, [categories, setSelectedCategory]);

  return (
    <div className="category_list">
      <div className="flex max-w-[100%] overflow-x-auto space-x-[5px]">
        {categories.map((category) => {
          return (
            <>
              <Item
                key={category.id}
                category={category}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setIsCategory={setIsCategory}
              />
              <div className="flex max-w-[100%] overflow-x-auto space-x-[5px] category_children">
                {isCategroy &&
                  category?.children.length > 0 &&
                  category?.children.map((child) => (
                    <div
                      className={`category-btn ${
                        child.id === selectedCategory ? "active" : ""
                      }`}
                    >
                      <h3
                        onClick={() => {
                          setSelectedCategory(child.id);
                        }}
                      >
                        {child.name}
                      </h3>
                    </div>
                  ))}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

const Item = ({
  category,
  selectedCategory,
  setSelectedCategory,
  setIsCategory,
}) => {
  const isSelected = selectedCategory === category.id;

  const handleClick = () => {
    setSelectedCategory(category.id);
    if (category?.children?.length > 0) {
      setIsCategory(true);
    } else {
      setIsCategory(false);
    }
  };

  return (
    <div
      key={category.id}
      className={`category-btn ${isSelected ? "active" : ""}`}
      onClick={handleClick}
    >
      <h2>{category.name}</h2>
    </div>
  );
};
