import { useEffect, useState } from "react";
import qs from "qs";
import i18next from "i18next";

// axios
import axios from "axios";
// notistack
import { enqueueSnackbar } from "notistack";
// react-router-dom
import { useLocation } from "react-router-dom";
// store
import useStore from "../../store";
// scss
import "./header.scss";
import Loading from "../Loading/Loading";
import HamburgerMenu from "./HamburgerMenu";
import useTranslation from "../../hooks/useTranslation";
import LanguageDropdown from "./LanguageDropdown";

export default function Header() {
  const { company, showInvoice, setShowInvoice } = useStore((state) => state);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableId = queryParams.get("table_id");
  const [order, setOrder] = useState();
  const { tr } = useTranslation();

  const path = location.pathname.split("/").pop();

  async function callWaiter() {
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/tables/${tableId}/waiter/call`,
      // body request
      data: {
        table_id: tableId,
        organization_id: path,
      },
      method: "POST",
    })
      .then((response) => {
        enqueueSnackbar("Официант вызван");
      })
      .catch(function (error) {
        enqueueSnackbar(error?.response?.data?.message);
      });
  }

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/tables/${tableId}/orders/all`,
      params: {
        include: "activeOrders",
      },

      method: "GET",
    })
      .then((response) => {
        setOrder(response.data);
      })
      .catch(function (error) {});
  }, [tableId, showInvoice]);

  return (
    <>
      {path === "qr-code" ? (
        <div></div>
      ) : (
        <div className="navbar bg-[#a5141a] py-[20px] rounded-b-[12px] ">
          <div className="container">
            <div className=" flex justify-between">
              <div className="flex items-center">
                <HamburgerMenu />
                <LanguageDropdown />
              </div>
              <h3 className="text-[#fff] text-[25px] font-semibold  title">
                {company?.name}
              </h3>
            </div>
            <div className="flex flex-row items-center justify-between my-5">
              <div className="flex items-center">
                {company?.logo?.thumbnails?.normal?.src && (
                  <img
                    className="w-[80px] h-auto rounded-[6px]"
                    src={company?.logo?.thumbnails?.normal?.src}
                    alt="logo"
                  />
                )}
              </div>

              <div className="flex flex-col">
                <div
                  onClick={callWaiter}
                  className="bg-[#fff] text-[#a5141a] px-[5px] py-[7px] rounded-[6px] cursor-pointer"
                >
                  {tr("Подозвать официанта")}
                </div>
                {order?.length > 0 ? (
                  <div
                    onClick={() => setShowInvoice(true)}
                    className="bg-[#fff] text-[#a5141a] px-[20px] py-[5px] rounded-[6px] cursor-pointer mt-[15px]"
                  >
                    {tr("Просмотреть счет")}
                  </div>
                ) : order?.length === 0 ? (
                  ""
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
