import React from "react";
// notistack
import { SnackbarProvider } from "notistack";
// react-router-dom
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// pages
import QrCode from "./pages/QrCode";
// components
import Header from "./components/Header/Header";
import MenuItems from "./components/MenuItems/MenuItems";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS

function App() {
  return (
    <Router>
      <div className="flex flex-col m-auto">
        <SnackbarProvider />
        <Header />
        <Routes>
          <Route path="/:id" element={<MenuItems />} />
          <Route path="/qr-code" element={<QrCode />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
