import React, { useState } from "react";
import "./style.css";
import LanguageDropdown from "../LanguageDropdown";
import useTranslation from "../../../hooks/useTranslation";

export default function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const { tr } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="menu-box">
      <button
        onClick={toggleMenu}
        className="flex flex-col items-center justify-center w-10 h-10 space-y-1 bg-gray-800 rounded-md focus:outline-none bg-[#fff]/[0.15]"
      >
        <span
          className={`block w-5 h-1 bg-white rounded transition-transform ${
            isOpen ? "rotate-45 translate-y-2" : ""
          }`}
        ></span>
        <span
          className={`block w-5 h-1 bg-white rounded ${
            isOpen ? "opacity-0" : ""
          }`}
        ></span>
        <span
          className={`block w-5 h-1 bg-white rounded transition-transform ${
            isOpen ? "-rotate-45 -translate-y-2" : ""
          }`}
        ></span>
      </button>

      {/* Full-Screen Overlay Menu */}
      <div
        className={`fixed inset-0 bg-white text-purple-700 flex flex-col items-center justify-start p-8 transform transition-transform z-10 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Close Button */}
        <button
          onClick={toggleMenu}
          className="absolute top-4 right-4 w-8 h-8 text-white bg-purple-700 rounded-full flex items-center justify-center focus:outline-none"
        >
          ✕
        </button>
        <div className="absolute top-4 right-14 bg-transparent ">
          <LanguageDropdown />
        </div>

        {/* Logo */}
        <div className="flex flex-col items-center">
          <img src="logo.png" alt="Foodee" className="w-32 mb-4 z-99" />
          <p className="text-sm text-center">
            {tr("Онлайн меню для ресторанов")}
          </p>
        </div>

        {/* Content */}
        <div className="text-center my-15">
          <h3 className="text-lg font-semibold mb-4">
            {tr("Мы будем благодарны, если сможем вам помочь!")}
          </h3>
          <p className="text-sm mb-4">
            {tr("По дополнительным вопросам обращайтесь к нам через телеграмм")}{" "}
            <a
              href="https://t.me/botir9696"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-purple-700 font-bold">
                {tr("@zimzim_admin")}
              </span>
            </a>
            <br />
            {tr("или вы можете позвонить в наш центр")} <br />
            <a href="tel:+998900175551" class="text-purple-700 font-bold">
              <span className="text-purple-700 font-bold my-10">
                +998-90 017-55-51
              </span>{" "}
            </a>
            <a href="tel:+998909999052" class="text-purple-700 font-bold">
              <span className="text-purple-700 font-bold mx-10">
                +998-90 999-90-52
              </span>
            </a>
          </p>
          <h4 className="text-sm font-semibold mb-4">
            {tr("Вы можете найти нас в социальных сетях.")}
          </h4>
          <div className="flex justify-center space-x-4">
            <a
              href="https://www.instagram.com/zimzim.uz/"
              className="text-purple-700"
            >
              <i className="fab fa-instagram text-2xl"></i>
            </a>
            <a href="https://t.me/zimzim_uz" className="text-purple-700">
              <i className="fab fa-telegram text-2xl"></i>
            </a>
          </div>
        </div>

        {/* Button */}
        <button className="bg-purple-700 text-white my-20 px-6 py-2 rounded-full">
          {tr("Станьте нашим клиентом")}
        </button>
      </div>
    </div>
  );
}
