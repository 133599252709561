import useStore from "../store";

const useTranslation = () => {
  const { currentLangCode, translations } = useStore((state) => state);

  const findTranslation = (key) => {
    if (!translations || typeof translations !== "object") {
      return key;
    }

    const translation = Object.values(translations).find((t) => t.key === key);

    if (!translation || !translation.value) {
      return key;
    }

    return translation.value[currentLangCode] || key;
  };

  const tr = (key) => findTranslation(key);

  return { tr };
};

export default useTranslation;
