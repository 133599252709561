import React, { useState, useEffect } from "react";
// axios
import axios from "axios";
// store
import useStore from "../../store";
// react-router-dom
import { useLocation } from "react-router-dom";

export default function Invoice() {
  const { orders, setOrders } = useStore((state) => state);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableId = queryParams.get("table_id");

  const [iframeHeight, setIframeHeight] = useState("1000px");

  async function fetchOrders() {
    if (!tableId) return;
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/tables/${tableId}/orders`,
      method: "GET",
    })
      .then((response) => {
        setOrders(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOrders();
    const height = document.querySelector(".ReactModal__Content").offsetHeight;
    setIframeHeight(`${height}`);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <iframe
        src={orders?.config?.url}
        title="invoice"
        width="100%"
        height={iframeHeight - 50}
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}
